import { mdiChevronDown, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const SelectCreate = ({ onChange, options: initialOptions, value, className, type }) => {
  const inputRef = useRef<HTMLInputElement>();
  const dropdownRef = useRef<HTMLDivElement>();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [createdValue, setCreatedValue] = useState(value);

  // Use custom hook to detect clicks outside the component
  useOnClickOutside(dropdownRef, () => {
    if (optionsOpen) {
      setOptionsOpen(false);
      setCreatedValue('');
    }
  });

  const options = useMemo(() => {
    if (!initialOptions) return [];
    return initialOptions.filter(
      (option) => option.toLowerCase().includes(createdValue.toLowerCase()) && !value.toLowerCase().includes(option.toLowerCase()),
    );
  }, [initialOptions, createdValue]);

  const handleInputChange = useCallback((e) => {
    setCreatedValue(e.target.value);
  }, []);

  return (
    <div className={cls('relative')}>
      <input
        ref={inputRef}
        placeholder={`Add new ${type}`}
        className={cls(className)}
        onChange={handleInputChange}
        value={createdValue}
        onFocus={() => setOptionsOpen(true)}
      />
      <div
        ref={dropdownRef}
        className={cls(
          optionsOpen ? 'scale-y-100 ' : 'scale-y-0',
          'absolute z-50 mt-1 block w-full origin-top divide-y rounded-md border bg-white p-2 shadow transition duration-150 ease-in-out sm:text-sm sm:leading-5',
        )}
      >
        {options.length ? (
          <>
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => {
                  setCreatedValue('');
                  onChange(option);
                  setOptionsOpen(false);
                }}
                className="flex w-full items-center py-1"
                type="button"
              >
                {option}
              </button>
            ))}
            {createdValue && (
              <button
                onClick={() => {
                  onChange(createdValue);
                  setCreatedValue('');
                  setOptionsOpen(false);
                }}
                className="flex w-full items-center py-1"
                type="button"
              >
                <Icon size={1} path={mdiPlus} />
                Add as new {type}
              </button>
            )}
          </>
        ) : (
          <button
            onClick={() => {
              onChange(createdValue);
              setCreatedValue('');
              setOptionsOpen(false);
            }}
            className="flex w-full items-center py-1"
            type="button"
          >
            <Icon size={1} path={mdiPlus} />
            Add as new {type}
          </button>
        )}
      </div>
      <div
        onClick={() => {
          if (!inputRef.current) return;
          inputRef?.current.focus();
        }}
        className="absolute right-2 top-0 flex h-full items-center justify-center"
      >
        <Icon path={mdiChevronDown} size={1} className={cls(optionsOpen ? 'rotate-180' : 'rotate-0', 'duration-200')} />
      </div>
    </div>
  );
};

// Custom hook to detect clicks outside an element
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
